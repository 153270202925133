import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'

export const usePlansSection = () => {
  const [selectedPlan, setSelectedPlan] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const router = useRouter()

  const { control, setValue, handleSubmit } = useForm({
    mode: 'onChange'
  })

  const onSelectPlan = ({ plan_price, slug_name }) => {
    setSelectedPlan(slug_name)
    setValue('plan_price', plan_price)
  }

  const onSubmit = () => {
    setIsLoading(true)

    const params = selectedPlan ? `?plano=${selectedPlan}` : ''
    router.push(`${basePath}formulario/dados-basicos/${params}`) // eslint-disable-line no-undef
  }

  return {
    control,
    isLoading,
    onSelectPlan,
    onSubmit,
    handleSubmit
  }
}
