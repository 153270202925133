import {
  Button,
  Container,
  Icon,
  PlanList,
  Subtitle,
  Tag,
  Title
} from '@/components'

import { usePlansSection } from './usePlansSection'

import { CUSTOM_PLAN } from '@/constants/plans'

import styles from './styles.module.scss'

const CUSTOM_PLAN_URL = `${basePath}formulario/dados-basicos/?plano=${CUSTOM_PLAN.slug_name}` // eslint-disable-line no-undef

export const Plans = () => {
  const { control, isLoading, onSelectPlan, onSubmit, handleSubmit } =
    usePlansSection()

  return (
    <section id="planos" className={styles.plans}>
      <Container>
        <Tag bgColor="white" text="Planos Pós-pago" />

        <Title
          text={
            <>
              Escolha o <b>melhor plano para o seu négócio</b>
            </>
          }
          customClass={styles.plans__title}
        />

        <Subtitle
          variant="small"
          customClass={styles.plans__subtitle}
          text="Todos os planos são sujeitos a análise e incluem os relatórios básico, intermediário e completo para Pessoa Física e Jurídica, sem fidelidade."
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <PlanList
            control={control}
            onSelectPlan={onSelectPlan}
            onClickCTA={onSelectPlan}
          />

          <Button
            type="submit"
            id="plans-wantToHire-button"
            isLoading={isLoading}
            className={`
              ${styles.plans__button} 
              ${styles['plans__button--fixed']}
            `}
          >
            Quero contratar
          </Button>

          <Button
            tagName="a"
            rel="noreferrer"
            href={CUSTOM_PLAN_URL}
            variant="secondary"
            id="plans-custom-button"
            className={styles.plans__button}
          >
            Quero descobrir meu plano ideal
            <Icon name="equifax-arrow-right-circle" size="small" />
          </Button>
        </form>

        <p className={styles.plans__disclaimer}>
          A quantidade de consultas pode variar de acordo com o relatório
          utilizado. <br />A assinatura de qualquer plano possui renovação
          automática.
        </p>
      </Container>
    </section>
  )
}
